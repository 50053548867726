/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');*/

:root {
  --line: #f5f5f5;
  --grey: #999999;
  --black: #000000;
  --Zaapi2: #42a391;
  --Zaapi3: #d6d6d6;
  --Zaapi4: #4b4a4b;
  --Zaapi5: #ffffff;
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

li,
span {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.swal2-popup {
  color: white;
}
.swal2-title {
  color: white !important;
}
textarea {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
}
textarea:focus {
  outline: none;
}
::placeholder {
  color: #d6d6d6;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
}
.MuiOutlinedInput-root.Mui-focused,
.MuiOutlinedInput-notchedOutline {
  border-color: var(--Zaapi3) !important;
}
.MuiSelect-select:focus {
  background-color: #fff !important;
}
